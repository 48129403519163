import React, { useState, useEffect } from 'react';
import './Footer.css'; // Import your CSS file here

const Footer = () => {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    setIsAnimated(true);
  }, []);

  return (
    <footer className={`footer ${isAnimated ? 'animated' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p> সংশয় – চিন্তার মুক্তির আন্দোলন © 2023 All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
