import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const SEARCH_POSTS = gql`
  query SearchPosts($searchQuery: String!) {
    posts(where: { search: $searchQuery }) {
      edges {
        node {
          id
          slug
          title
          excerpt
          date
        }
      }
    }
  }
`;

function Navbar() {
  const [showLinks, setShowLinks] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchPosts, { loading, data }] = useLazyQuery(SEARCH_POSTS);
  const [showResults, setShowResults] = useState(false);

  const handleToggle = () => {
    setShowLinks(!showLinks);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setShowResults(false);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    searchPosts({ variables: { searchQuery } });
    setShowResults(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      searchPosts({ variables: { searchQuery } });
      setShowResults(true);
    }
  };

  const handleLinkClick = () => {
    setShowResults(false);
  };

  return (
    <nav>
      <div className="logo">
        <Link to="/">
          <img
            src="https://www.shongshoy.com/wp-content/uploads/2022/07/shongshoy_logo_220.svg"
            height="60px"
            alt="logo"
          />
        </Link>
      </div>
      <div className="links" id={showLinks ? "hidden" : ""}>
        <Link to="">মূলপাতা</Link>
        <Link to="">ইতিহাস</Link>
        <Link to="">আর্কাইভ</Link>
        <Link to="">গ্রন্থাগার</Link>
      </div>
      <div className="search-bar">
      <form className="formcenter" onSubmit={handleSearchSubmit}>
        <input
          type="text"
          placeholder="Search posts"
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
        />
        <button type="submit">Search</button>
      </form>
      {loading && <p>Loading...</p>}
      {data && showResults && (
        <div className="search-results">
          <h2>Search Results</h2>
          <ul>
            {data.posts.edges.map((edge) => (
              <li key={edge.node.id}>
                <Link to={`/${edge.node.slug}`} onClick={handleLinkClick}>
                  <h3>{edge.node.title}</h3>
                  <p>{edge.node.excerpt}</p>
                  <p>{edge.node.date}</p>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
    <button className="toggle-button" onClick={handleToggle}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>
    </nav>
  );
}

export default Navbar;
