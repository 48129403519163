import React from "react";
import PostsList from "../components/PostsList";
import Navbar from "../components/nav/Navbar";
import Footer from "../components/footer/Footer";

export default function HomePage() {
  return (
    <div className="page-container">
       <Navbar />
      <h1>Blog</h1>
      <PostsList />
      <Footer />
    </div>
    
  );
}
